import { graphql } from '@api/gql/gql'

export const photoCounts = graphql(`
    query photoCounts($input: PhotoCountsInput!) {
        photo_counts(input: $input) {
            total
            items {
                year
                count
            }
        }
        albums_ranges {
            id
            from_at
            to_at
            order_id
            order_status
        }
    }
`)
