import { graphql } from '@api/gql/gql'

export const subscriptionAlbumsDraft = graphql(`
    subscription albumsDraft($draftId: String!) {
        albums_draft(draft_id: $draftId) {
            type
            text
            order_id
        }
    }
`)
