import { DateTime } from 'luxon'

export type Range = {
  fromAt: DateTime;
  toAt: DateTime;
};

export type CreatingStatus = {
  title: string
  loading?: boolean
}

export type RangeVariant = {
  name: string
  from_at: DateTime
  to_at: DateTime
  count: number
  type: RangeVariantType
  order_id?: string
}

export type RangeVariantOrder = {
  type: RangeVariantType,
  order_id?: string
}

export enum RangeVariantType {
  DEFAULT = 'default',
  REORDER = 'reorder',
  CONTINUE = 'continue',
}

export type RangeYears = {
  fromYear: number
  toYear: number
}
