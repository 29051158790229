import { FC } from 'react'
import { useNavigate } from 'react-router'
import { AlbumsDraftType } from '@api/gql/graphql'
import { forceLogout } from '@api/graphql-endpoint'
import { useSubscription } from '@apollo/client'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Spinner } from '@nextui-org/spinner'
import { CreatingStatus } from '@pages/create-page/types'
import { clsx } from 'clsx'

import { subscriptionAlbumsDraft } from '../api'

type Props = {
  status: CreatingStatus;
  draftId: string;
};

export const Status: FC<Props> = (props) => {
  const navigate = useNavigate()
  const creatingAlbumsState = useSubscription(subscriptionAlbumsDraft, {
    variables: { draftId: props.draftId },
    onData: (res) => {
      if (res.data.data?.albums_draft?.type === AlbumsDraftType.GoOrder) {
        const orderId = res.data.data?.albums_draft.order_id
        if (!orderId) {
          return
        }

        amplitude.event({
          type: AmplitudeEvent.CreateCompleted,
          order_id: orderId,
        })

        navigate(`/orders/${orderId}`)
      }

      if (res.data.data?.albums_draft?.type === AlbumsDraftType.UnauthorizedError) {
        forceLogout()
        res.client.stop()
      }
    },
  })

  const data = creatingAlbumsState.data?.albums_draft ?? null
  const isError = data?.type === AlbumsDraftType.Error

  return (
    <div className="text-center mb-5">
      <div className={clsx(
        'flex justify-center font-bold text-2xl',
        data?.type === AlbumsDraftType.Error ? 'text-red-500' : 'text-black',
      )}
      >
        {!isError && props.status.loading && <Spinner size="sm" className="mr-2" />}
        {isError ? 'Error' : props.status.title}
      </div>
      <div className={clsx(
        'text-lg break-all',
        data?.type === AlbumsDraftType.Error ? 'text-red-400' : 'text-gray-600',
      )}
      >
        {data?.text || '...'}
      </div>
    </div>
  )
}
