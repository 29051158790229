import { FC, useMemo } from 'react'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clsx } from 'clsx'
import currency from 'currency.js'

type Props = {
  total: number
  subtotal: number
  className?: string
}

export const Total: FC<Props> = (props) => {
  const total = useMemo(
    () => currency((props.total ?? 0) / 100).format({ symbol: '$' }),
    [props.total],
  )

  const subtotal = useMemo(
    () => currency((props.subtotal ?? 0) / 100).format({ symbol: '$' }),
    [props.subtotal],
  )

  return (
    <div className={clsx('flex gap-4 items-center', props.className)}>
      <div className="text-gray-400 font-bold text-base lg:text-xl line-through">
        {subtotal}
      </div>
      <div className="text-white font-bold text-base lg:text-2xl">
        {total}
      </div>
      <div
        className="inline-flex text-tiny md:text-base items-center px-2 py-1 lg:px-5 lg:py-2 gap-2 rounded-full text-white"
      >
        <FontAwesomeIcon icon={faTruck} className="text-white" />
        Free delivery
      </div>
    </div>
  )
}
