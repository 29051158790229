import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Order, OrderStatus } from '@api/gql/graphql'
import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import { deleteOrder } from '@pages/order-page/api'
import { DateTime } from 'luxon'

import { AlbumItem } from './album-item'

type Props = {
  order: Order;
};

export const OrderItem: FC<Props> = (props) => {
  const apolloClient = useApolloClient()
  const { openModal } = useModal()

  const [deleteOrderMutation, deleteOrderState] = useMutation(deleteOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onDeleteOrder = () => {
    if (!props.order?.id) {
      return
    }

    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Delete an order?',
      message: 'All changes will be lost',
      onCancel: () => {},
      onConfirm: () => {
        deleteOrderMutation({ variables: { orderId: props.order.id } })
          .then(() => {
            apolloClient.cache.evict({ id: `Order:${props.order.id}` })
            apolloClient.cache.gc()
          })
      },
    })
  }

  const createdAt = useMemo(
    () => DateTime.fromISO(props.order.created_at).toFormat('dd MMM yyyy, h:mm a'),
    [props.order.created_at],
  )

  return (
    <div className="border rounded-md px-4 py-2 border-gray-200 hover:border-gray-400 cursor-pointer">
      <Link to={`/orders/${props.order.id}`}>
        <div className="font-bold text-base lg:text-lg">
          {createdAt}
        </div>
        <div className="text-xs lg:text-base">
          ID:
          {' '}
          {props.order.id}
          ,
          status:
          {props.order.status}
        </div>
      </Link>

      <div className="border-t pt-2 mt-2 border-neutral-200">
        {props.order.albums?.map((album) => (
          <AlbumItem key={album.id} album={album} />
        ))}
      </div>

      {props.order.status === OrderStatus.Created && (
        <div className="border-t pt-2 mt-2 border-neutral-200">
          <Button
            variant="bordered"
            size="sm"
            onClick={onDeleteOrder}
            isLoading={deleteOrderState.loading}
          >
            Delete draft
          </Button>
        </div>
      )}
    </div>
  )
}
