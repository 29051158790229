import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { CoverType } from '@api/gql/graphql'
import { getProfile } from '@api/schemas/get-profie'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { toast } from '@components/toast'
import {
  coverTypes,
  MAX_PHOTOS_PER_ALBUM,
  MIN_PHOTOS_PER_ALBUM,
} from '@constants/album'
import { AlertError } from '@constants/alert-error'
import { PageName } from '@constants/analytic'
import { AuthorizationError } from '@constants/authorization-error'
import { ERROR } from '@constants/error'
import { LOGIN_URL } from '@constants/link'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useApolloError } from '@helpers/apollo-error'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { declension } from '@helpers/i18n'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Spinner } from '@nextui-org/spinner'
import cookie from 'js-cookie'
import { DateTime } from 'luxon'

import {
  createAlbums,
  createAlbumsDraftId,
  photoCounts,
  reorder,
} from './api'
import { RangeVariantItem, Status } from './components'
import { CreatingStatus, Range, RangeVariant } from './types'
import { AlbumsRangeFinder } from './utils'

export const CreatePage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { openModal } = useModal()

  const [createAlbumsDraftIdMutation, createAlbumsDraftIdState] = useMutation(createAlbumsDraftId, {
    fetchPolicy: 'network-only',
  })
  const [createAlbumsMutation, createAlbumsState] = useMutation(createAlbums)
  const [reorderMutation, reorderState] = useMutation(reorder)
  const [photoCountsQuery, photoCountsState] = useLazyQuery(photoCounts, {
    fetchPolicy: 'network-only',
  })
  const [getProfileQuery] = useLazyQuery(getProfile)

  const [creatingStatus, setCreatingStatus] = useState<CreatingStatus>({
    title: '...',
    loading: true,
  })

  const coverType = searchParams.get('cover_type') as CoverType

  // Заданный диапазон
  const initialRange = useMemo((): Range | null => {
    const range = searchParams.get('range')

    if (!range) {
      return null
    }

    if (range === 'today') {
      return {
        fromAt: DateTime.fromObject({
          year: 2004, month: 1, day: 4, hour: 0, minute: 0,
        }),
        toAt: DateTime.now(),
      }
    }

    const [fromStr, toStr] = range.split(',')

    return {
      fromAt: DateTime.fromFormat(fromStr, 'yyyy-MM-dd'),
      toAt: DateTime.fromFormat(toStr, 'yyyy-MM-dd'),
    }
  }, [])

  const albumsDraftId = createAlbumsDraftIdState.data?.create_albums_draft_id ?? null

  // Детали заказа по умолчанию
  const facebookClickIds = {
    fbp: cookie.get('_fbp') || undefined,
    fbc: cookie.get('_fbc') || undefined,
  }

  // Варианты диапазонов
  const rangeVariants = useMemo(
    (): RangeVariant[] => {
      if (!photoCountsState.data?.photo_counts.items) {
        return []
      }

      const albumsRangeFinder = new AlbumsRangeFinder({
        albumsRanges: photoCountsState.data?.albums_ranges ?? [],
        photoCounts: photoCountsState.data.photo_counts.items,
        total: photoCountsState.data.photo_counts.total,
        confirm: () => new Promise((resolve) => { resolve(null) }),
        navigate: (path) => navigate(path),
        reorder: () => new Promise((resolve) => {
          resolve('1')
        }),
      })

      return albumsRangeFinder.getRangeVariants()
    },
    [photoCountsState.data],
  )

  /**
   * Получение профиля.
   *
   * Выбрасывает исключение если пользователь не авторизован
   */
  const fetchProfile = async () => {
    const getProfileState = await getProfileQuery()
    const err = getProfileState.error?.graphQLErrors
    if (err?.length && err[0].extensions?.code === ERROR.UNAUTHORIZED) {
      throw new AuthorizationError(err[0].message)
    }

    return getProfileState
  }

  // Обработка ошибок
  const handleError = (e: unknown) => {
    if (e instanceof AlertError) {
      openModal({
        type: ModalType.ALERT_MODAL,
        title: e.message,
        description: e.getText(),
      })
    } else if (e instanceof AuthorizationError) {
      window.location.href = LOGIN_URL
    } else {
      const error = (e as unknown as Error).message

      setCreatingStatus((s) => ({
        ...s,
        title: `Error: ${error}`,
        loading: false,
      }))

      toast.error(error)
    }
  }

  // Форматирует подзаголовок исходя из диапазона
  const formatSubtitle = (fromYear: number, toYear: number): string => {
    if (fromYear === toYear) {
      return `${fromYear}`
    }

    return `${fromYear} - ${toYear}`
  }

  const onConfirm = (title: string, description: string): Promise<null> => {
    return new Promise((resolve) => {
      openModal({
        type: ModalType.CONFIRM_MODAL,
        title,
        message: description,
        onConfirm: () => resolve(null),
      })
    })
  }

  // Запуск получение вариантов диапазона
  const runReceivingRangeVariants = async () => {
    try {
      setCreatingStatus((s) => ({
        ...s,
        title: 'Creating order...',
        loading: true,
      }))

      await fetchProfile()

      const albumsDraftIdResp = await createAlbumsDraftIdMutation()
      const draftId = albumsDraftIdResp.data?.create_albums_draft_id
      if (!draftId) {
        return
      }

      setCreatingStatus((s) => ({
        ...s,
        title: 'Searching photos...',
      }))

      await photoCountsQuery({ variables: { input: { draft_id: draftId, use_cache: false } } })

      setCreatingStatus((s) => ({
        ...s,
        title: 'Select a range',
      }))
    } catch (e) {
      handleError(e)
    } finally {
      setCreatingStatus((s) => ({
        ...s,
        loading: false,
      }))
    }
  }

  // Создает альбомы
  const onSelectRangeVariant = async (rangeVariant: RangeVariant) => {
    if (!photoCountsState.data?.photo_counts || !albumsDraftId) {
      return
    }

    setCreatingStatus((s) => ({
      ...s,
      title: 'Making album...',
      loading: true,
    }))

    await createAlbumsMutation({
      variables: {
        input: {
          subtitle: `${formatSubtitle(rangeVariant.from_at.year, rangeVariant.to_at.year)}`,
          draft_id: albumsDraftId,
          min_photos_per_album: MIN_PHOTOS_PER_ALBUM,
          max_photos_per_album: MAX_PHOTOS_PER_ALBUM,
          from_at: rangeVariant.from_at.setZone('UTC'),
          to_at: rangeVariant.to_at.setZone('UTC'),
          cover_type: coverTypes.includes(coverType) ? coverType : undefined,
          ...facebookClickIds,
        },
      },
      onError: (e) => {
        setCreatingStatus({
          title: `Error: ${e.graphQLErrors[0].message}`,
          loading: true,
        })
      },
    })
  }

  // Авто создание альбома с заданным диапазоном
  const runAutoCreate = async () => {
    try {
      amplitude.event({
        type: AmplitudeEvent.CreateStarted,
      })

      setCreatingStatus((s) => ({
        ...s,
        title: 'Creating order...',
        loading: true,
      }))

      await fetchProfile()

      const albumsDraftIdResp = await createAlbumsDraftIdMutation()
      const draftId = albumsDraftIdResp.data?.create_albums_draft_id
      if (!draftId) {
        return
      }

      setCreatingStatus((s) => ({
        ...s,
        title: 'Searching photos...',
      }))

      const photoCountsResp = await photoCountsQuery({
        variables: { input: { draft_id: draftId } },
      })
      if (!photoCountsResp.data?.photo_counts) {
        return
      }

      const albumsRangeFinder = new AlbumsRangeFinder({
        albumsRanges: photoCountsResp.data?.albums_ranges ?? [],
        photoCounts: photoCountsResp.data.photo_counts.items,
        total: photoCountsResp.data.photo_counts.total,
        initialRange,
        confirm: onConfirm,
        navigate: (path) => navigate(path),
        reorder: (orderId) => new Promise((resolve) => {
          reorderMutation({
            variables: {
              input: {
                order_id: orderId,
                ...facebookClickIds,
              },
            },
          }).then((res) => {
            if (!res.data?.reorder.id) {
              return
            }

            resolve(res.data?.reorder.id)
          })
        }),
      })

      const findRangeResult = await albumsRangeFinder.findRange()
      if (!findRangeResult) {
        return
      }

      setCreatingStatus((s) => ({
        ...s,
        title: 'Making album...',
      }))

      await createAlbumsMutation({
        variables: {
          input: {
            subtitle: `${formatSubtitle(findRangeResult.fromYear, findRangeResult.toYear)}`,
            draft_id: draftId,
            min_photos_per_album: MIN_PHOTOS_PER_ALBUM,
            max_photos_per_album: MAX_PHOTOS_PER_ALBUM,
            cover_type: coverTypes.includes(coverType) ? coverType : undefined,
            from_at: DateTime.fromObject({
              year: findRangeResult.fromYear,
              month: 1,
              day: 1,
              hour: 0,
              minute: 0,
              second: 0,
            }).setZone('UTC'),
            to_at: DateTime.fromObject({
              year: findRangeResult.toYear,
              month: 12,
              day: 31,
              hour: 23,
              minute: 59,
              second: 59,
            }).setZone('UTC'),
            ...facebookClickIds,
          },
        },
      })
    } catch (e) {
      handleError(e)
    } finally {
      setCreatingStatus((s) => ({
        ...s,
        loading: false,
      }))
    }
  }

  const onReorder = async (rangeVariant: RangeVariant) => {
    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'You have already created a book in this period',
      message: 'Those photos will be included in this album',
      onConfirm: async () => {
        // Если выбранный диапазон включает текущий год, то не копируем альбомы
        // Это для того, чтобы новые фотографии включились в новый альбом
        if (DateTime.now().year === rangeVariant.to_at.year) {
          await onSelectRangeVariant(rangeVariant)
          return
        }

        try {
          if (!rangeVariant.order_id) {
            return
          }

          setCreatingStatus((s) => ({
            ...s,
            title: 'Creating order',
            loading: true,
          }))

          const reorderResp = await reorderMutation({
            variables: {
              input: {
                order_id: rangeVariant.order_id,
                ...facebookClickIds,
              },
            },
          })
          if (reorderResp.data?.reorder.id) {
            navigate(`/orders/${reorderResp.data?.reorder.id}`)
          }
        } finally {
          setCreatingStatus((s) => ({
            ...s,
            title: '...',
            loading: false,
          }))
        }
      },
    })
  }

  const run = async () => {
    // При заданном диапазоне мы автоматически начинаем создавать альбом
    if (initialRange) {
      await runAutoCreate()
    } else {
      // Если нету диапазона мы предоставляем выбор диапазона
      await runReceivingRangeVariants()
    }
  }

  const isRun = useRef<boolean>(false)

  // Время монтирования
  const mountedTime = useRef<number>(Date.now())

  useEffect(() => {
    if (isRun.current) {
      return
    }

    isRun.current = true
    run()
  }, [])

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Create,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Create,
    })

    facebookPixel.event('PageView')
  }, [])

  const isLoading = createAlbumsDraftIdState.loading
      || photoCountsState.loading
      || createAlbumsState.loading

  const pageError = useApolloError([
    createAlbumsDraftIdState.error,
    photoCountsState.error,
    createAlbumsState.error,
  ])

  const isEmpty = !pageError
      && !isLoading
      && !rangeVariants.length
      // Это условие нужно для того чтобы не показывать "у вас нету фотографий" раньше времени
      && Date.now() > mountedTime.current + (1000 * 3)

  return (
    <div className="flex justify-center flex-col items-center lg:pt-[50px] page">
      <div className="w-full md:w-auto md:min-w-[400px] md:max-w-[1000px]">
        <Alert type={AlertType.error} message={pageError} className="mb-4" />

        {albumsDraftId ? (
          <Status
            status={creatingStatus}
            draftId={albumsDraftId}
          />
        ) : (
          <div className="flex items-center justify-center"><Spinner /></div>
        )}

        {!initialRange && (
          <>
            <div className="flex flex-col gap-y-2 mb-4 px-4">
              {rangeVariants.map((rangeVariant) => (
                <RangeVariantItem
                  key={rangeVariant.name}
                  rangeVariant={rangeVariant}
                  isDisabled={creatingStatus.loading || reorderState.loading}
                  onSelect={() => onSelectRangeVariant(rangeVariant)}
                  onContinue={() => navigate(`/orders/${rangeVariant.order_id}`)}
                  onReorder={() => onReorder(rangeVariant)}
                />
              ))}
            </div>

            {isEmpty && (
              <div className="text-sm text-center text-gray-500">
                <FontAwesomeIcon icon={faWarning} />
                {' '}
                Not enough photos.
                {' '}
                {`Minimum ${MIN_PHOTOS_PER_ALBUM} ${declension(MIN_PHOTOS_PER_ALBUM, ['photo', 'photos', 'photos'])}`}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default CreatePage
