import { graphql } from '@api/gql/gql'

export const getAlbum = graphql(`
    query getAlbum($id: ID!) {
        album(id: $id) {
            id
            user_id
            title
            subtitle
            creator_name
            cover_type
            page_format
            book_id
            spine_id
            cover_id
            book_status
            cover_status
            spine_status
            from_at
            to_at
            updated_at
            created_at
            pages {
                id
                album_id
                sort
                width
                height
                images {
                    id
                    album_page_id
                    external_id
                    image_small_url
                    image_medium_url
                    image_large_url
                    width
                    height
                    position_x
                    position_y
                    sort
                    updated_at
                    created_at
                    deleted_at
                }
            }
        }
    }
`)
