import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { PageTitle } from '@components/page-title'
import { Pagination } from '@components/pagination'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useApolloError } from '@helpers/apollo-error'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { usePagination } from '@helpers/use-pagination'
import { Spinner } from '@nextui-org/spinner'

import { getOrders } from './api'
import { OrderItem } from './components'

export const OrdersPage = () => {
  const { paginationInput, onPage } = usePagination({ limit: 20 })

  const getOrdersOwnState = useQuery(getOrders, {
    fetchPolicy: 'network-only',
    variables: { pagination: paginationInput },
  })

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Orders,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Orders,
    })

    facebookPixel.event('PageView')
  }, [])

  const pageError = useApolloError([getOrdersOwnState.error])

  const drafts = getOrdersOwnState.data?.orders_own_drafts ?? []
  const orders = getOrdersOwnState.data?.orders_own.items ?? []
  const pageInfo = getOrdersOwnState.data?.orders_own?.page_info

  return (
    <div className="page">
      <Alert type={AlertType.error} message={pageError} />

      <div className="mb-14">
        <PageTitle title="Drafts" className="mb-5" />

        {!getOrdersOwnState.loading && (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 mb-4">
          {drafts.map((order) => <OrderItem key={order.id} order={order} />)}
        </div>
        )}

        {!drafts.length && !getOrdersOwnState.loading && (
        <div className="text-gray-500 text-center">
          No drafts found
        </div>
        )}
      </div>

      <PageTitle title="My orders" className="mb-5" />

      {!getOrdersOwnState.loading && (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 mb-4">
          {orders.map((order) => <OrderItem key={order.id} order={order} />)}
        </div>
      )}

      {getOrdersOwnState.loading && <Spinner />}

      {!orders.length && !getOrdersOwnState.loading && (
      <div className="text-gray-500 text-center">
        No orders found
      </div>
      )}

      <Pagination
        pageInfo={pageInfo}
        onPage={onPage}
      />
    </div>
  )
}

export default OrdersPage
