import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { OrderStatus } from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { AlbumEditor, AlbumsNavigation } from '@share/album'

import { getOrder } from './api'
import { Checkout, PaidInfo } from './components'

export const OrderPage = () => {
  const { orderId } = useParams<{ orderId: string }>()

  const [currentAlbumId, setCurrentAlbumId] = useState<string | null>(null)

  const orderState = useQuery(getOrder, {
    variables: { orderId: orderId as string },
    onCompleted: (res) => {
      const albums = res.order?.albums ?? []
      if (albums.length) {
        setCurrentAlbumId(albums[0].id)
      }
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Order,
      order_id: orderId,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Order,
    })

    facebookPixel.event('PageView')
  }, [])

  const onChangeCurrentAlbumId = (albumId: string) => {
    amplitude.event({
      type: AmplitudeEvent.AlbumClick,
      album_id: albumId,
    })

    setCurrentAlbumId(albumId)
  }

  const order = orderState.data?.order ?? null
  const albums = order?.albums ?? []
  const isStatusCreated = orderState.data?.order?.status === OrderStatus.Created

  return (
    <div className="page">
      {!!currentAlbumId && albums.length > 1 && (
        <div className="mb-3">
          <AlbumsNavigation
            albums={albums}
            currentAlbumId={currentAlbumId}
            onChangeCurrentAlbumId={onChangeCurrentAlbumId}
          />
        </div>
      )}

      {!!currentAlbumId && order && (
        <AlbumEditor
          albumId={currentAlbumId}
          key={currentAlbumId}
          editable={isStatusCreated}
        >
          {(count) => (isStatusCreated
            ? <Checkout order={order} pagesCount={count} />
            : <PaidInfo order={order} />)}
        </AlbumEditor>
      )}
    </div>
  )
}

export default OrderPage
