import { useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { OAuthType } from '@api/gql/graphql'
import { useApolloClient, useMutation } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useApolloError } from '@helpers/apollo-error'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { tokenStore } from '@helpers/token-store'
import { Spinner } from '@nextui-org/spinner'

import { loginFacebook } from './api'

export const LoginFacebookPage = () => {
  const apolloClient = useApolloClient()
  const [searchParams] = useSearchParams()

  const fetched = useRef<boolean>(false)
  const [loginFacebookMutation, loginFacebookState] = useMutation(
    loginFacebook,
    {
      onCompleted: (res) => {
        if (!res.login_facebook?.user?.id) {
          return
        }

        amplitude.setUser(res.login_facebook.user)
        amplitude.event({
          type: AmplitudeEvent.LoginCompleted,
          oauth_type: OAuthType.Facebook,
          user_id: res.login_facebook.user.id,
        })
        ga.setUser(res.login_facebook.user)

        const redirectUrl = searchParams.get('state')

        window.location.href = redirectUrl
          ? decodeURIComponent(redirectUrl)
          : '/create'
      },
      onError: () => {},
    },
  )

  const pageError = useApolloError([loginFacebookState.error])
  const facebookError = useMemo(() => searchParams.get('error'), [searchParams])

  useEffect(() => {
    const isError = searchParams.get('error')
    if (isError) {
      amplitude.event({
        type: AmplitudeEvent.PageView,
        name: PageName.OAuthFacebook,
        error: true,
        error_code: searchParams.get('error_code'),
        error_description: searchParams.get('error_description'),
        error_reason: searchParams.get('error_reason'),
        url: window.location.href,
      })
    } else {
      amplitude.event({
        type: AmplitudeEvent.PageView,
        name: PageName.OAuthFacebook,
      })
    }

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.OAuthFacebook,
    })

    facebookPixel.event('PageView')
  }, [])

  useEffect(() => {
    if (fetched.current) {
      return
    }

    fetched.current = true

    const code = searchParams.get('code')
    if (!code) {
      return
    }

    loginFacebookMutation({ variables: { code } }).then((res) => {
      if (!res.data?.login_facebook.token) {
        return
      }

      tokenStore.set(res.data?.login_facebook.token)

      apolloClient.refetchQueries({ include: ['profile'] })
    })
  }, [])

  return (
    <div className="flex flex-col items-center justify-center">
      <Alert type={AlertType.error} message={pageError || facebookError} />

      {loginFacebookState.loading && (
      <div className="flex flex-col justify-center items-center gap-4">
        <Spinner />
        {' '}
        Loading...
      </div>
      )}
    </div>
  )
}

export default LoginFacebookPage
