import { graphql } from '@api/gql/gql'

export const loginFacebook = graphql(`
    mutation loginFacebook($code: String!) {
        login_facebook(code: $code) {
            user {
                id
                name
                role
                fb_id
                avatar_url
                permissions
            }
            token
        }
    }
`)
