import { FC, useMemo } from 'react'
import {
  Order,
  OrderAddressType,
  OrderStatus,
} from '@api/gql/graphql'
import { faInfoCircle, faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getStripeLink } from '@helpers/stripe-link'
import { Link } from '@nextui-org/link'
import { Tooltip } from '@nextui-org/tooltip'
import { AddressItem } from '@pages/admin-orders-page/components/address-item'
import { clsx } from 'clsx'
import currency from 'currency.js'
import { DateTime } from 'luxon'

type Props = {
  order: Order;
};

export const CellOrder: FC<Props> = (props) => {
  const createdAt = useMemo(
    () => DateTime.fromISO(props.order.created_at).toFormat('dd MMM yyyy, hh:mm'),
    [props.order.created_at],
  )

  const shippingAddress = useMemo(
    () => props.order.addresses?.find((address) => address.type === OrderAddressType.Shipping),
    [props.order.addresses],
  )

  const billingAddress = useMemo(
    () => props.order.addresses?.find((address) => address.type === OrderAddressType.Billing),
    [props.order.addresses],
  )

  const addressShort = useMemo(() => {
    if (!shippingAddress) {
      return '-'
    }

    return [
      shippingAddress.country,
      shippingAddress.state,
      shippingAddress.city,
    ].filter((address) => !!address).join(', ')
  }, [shippingAddress])

  const total = useMemo(
    () => currency((props.order.total ?? 0) / 100).format({ symbol: '$' }),
    [props.order.total],
  )

  return (
    <div className="">
      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Date:</div>
        <div className="">{createdAt}</div>
      </div>

      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Status:</div>
        <div
          className={clsx(
            props.order.status === OrderStatus.Paid && 'text-green-700',
          )}
        >
          {props.order.stripe_id ? (
            <Link
              href={getStripeLink(props.order.stripe_id)}
              target="_blank"
              className="flex items-center gap-x-1 text-black underline text-sm"
            >
              {props.order.status?.toUpperCase() ?? '-'}
              <FontAwesomeIcon
                icon={faLink}
                className="text-gray-400"
                size="sm"
              />
            </Link>
          ) : (
            '-'
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Total:</div>
        <div className="">
          {total}
        </div>
      </div>

      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Customer:</div>
        <div className="">{props.order.user?.name ?? '-'}</div>
      </div>

      <div className="grid grid-cols-2 py-1">
        <div className="text-gray-500">Addresses:</div>
        {shippingAddress ? (
          <div>
            <Tooltip
              delay={0}
              content={(
                <div className="w-[400px]">
                  <AddressItem address={shippingAddress} />
                  <AddressItem address={billingAddress} />
                </div>
              )}
            >
              <div className="flex gap-x-2 items-center">
                {addressShort}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-gray-400"
                  size="sm"
                />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="">-</div>
        )}
      </div>
    </div>
  )
}
