import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { Order, OrderStatus } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { Container } from '@components/container'
import { toast } from '@components/toast'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Button } from '@nextui-org/button'
import { clsx } from 'clsx'
import currency from 'currency.js'
import cookie from 'js-cookie'

import { reorder } from '../api'

type Props = {
  order: Order;
};

export const PaidInfo: FC<Props> = (props) => {
  const navigate = useNavigate()

  const [reorderMutation, reorderState] = useMutation(reorder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const total = useMemo(
    () => currency((props.order.total ?? 0) / 100).format({ symbol: '$' }),
    [props.order.total],
  )

  const facebookClickIds = {
    fbp: cookie.get('_fbp') || undefined,
    fbc: cookie.get('_fbc') || undefined,
  }

  const onReorder = async () => {
    amplitude.event({
      type: AmplitudeEvent.OrderClickReorder,
      order_id: props.order.id,
    })

    const reorderResp = await reorderMutation({
      variables: {
        input: {
          order_id: props.order.id,
          ...facebookClickIds,
        },
      },
    })
    if (reorderResp.data?.reorder.id) {
      navigate(`/orders/${reorderResp.data?.reorder.id}`)
    }
  }

  return (
    <div className="fixed bottom-0 w-full left-0 bg-black/70 px-2">
      <Container>
        <div className="grid grid-cols-2 md:grid-cols-2 items-center py-4">
          <div>
            <span className={clsx(
              'text-2xl font-bold',
              props.order.status === OrderStatus.Paid && 'text-green-400',
              props.order.status === OrderStatus.Cancelled && 'text-red-400',
            )}
            >
              {props.order.status?.toUpperCase()}
              {' '}
            </span>
            <span className="text-white font-bold text-base lg:text-2xl">
              {total}
            </span>
          </div>
          <div className="flex justify-end">
            <Button
              className="rounded-full text-xs px-5 py-4 lg:px-10 lg:py-8 lg:text-xl"
              color="primary"
              variant="solid"
              isLoading={reorderState.loading}
              onClick={onReorder}
            >
              Reprint
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}
