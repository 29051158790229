import { FC, useMemo } from 'react'
import {
  Album, AlbumPage, ImageSize, PageFormat,
} from '@api/gql/graphql'
import { AlbumPagePadding, parsePageFormat } from '@constants/album'
import {
  AlbumAdditional,
  AlbumBackCover,
  AlbumCover,
  AlbumIntro,
  AlbumPicture,
  PageWrapper,
} from '@share/album'

import { AlbumPageExtraFields, coverPagePadding, defaultPagePadding } from '../types'

type Props = {
  album: Album
  albumPage: AlbumPage & AlbumPageExtraFields
}

export const PageItem: FC<Props> = (props) => {
  const pageFormat = props.album?.page_format || PageFormat.Format_210x210
  const pagePadding: AlbumPagePadding = props.albumPage.isCover
    ? coverPagePadding : defaultPagePadding

  const windowSizes = useMemo(() => parsePageFormat(pageFormat), [pageFormat])
  const windowSizesInner = useMemo(
    () => parsePageFormat(pageFormat, pagePadding),
    [pageFormat],
  )

  return (
    <PageWrapper
      key={props.albumPage.id}
      pagePadding={pagePadding}
      windowSizes={windowSizes}
      windowSizesInner={windowSizesInner}
      className="text-[0.5em] md:text-[1.28em]"
      dev={import.meta.env.DEV}
    >
      {props.album && props.albumPage.isCover && (
        <AlbumCover album={props.album} />
      )}

      {props.albumPage.isContent && props.albumPage?.images?.map((image) => (
        <AlbumPicture
          key={image.external_id}
          image={image}
          pageWidth={props.albumPage.width || 0}
          pageHeight={props.albumPage.height || 0}
          windowWidth={windowSizesInner.width}
          windowHeight={windowSizesInner.height}
          imageSize={import.meta.env.DEV ? ImageSize.Small : ImageSize.Large}
          debug
        />
      ))}

      {props.albumPage.isAdditional && <AlbumAdditional />}
      {props.albumPage.isBackCover && <AlbumBackCover />}
      {props.albumPage.isIntro && <AlbumIntro />}
    </PageWrapper>
  )
}
