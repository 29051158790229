import { FC } from 'react'
import { clsx } from 'clsx'

type Props = {
  title: string
  className?: string
}

export const PageTitle: FC<Props> = (props) => {
  return (
    <div className={clsx(
      'flex gap-x-4 items-center',
      props.className,
    )}
    >
      <div className="w-full border-b" />
      <div className="w-auto min-w-[100px] lg:min-w-[200px] text-xl lg:text-4xl break-keep text-center">
        {props.title}
      </div>
      <div className="w-full border-b" />
    </div>
  )
}
