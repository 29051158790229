import { AlbumPagePadding, initialAlbumPagePadding } from '@constants/album'

export type AlbumPageExtraFields = {
  isContent: boolean
  isCover: boolean
  isAdditional: boolean
  isBackCover: boolean
  isIntro: boolean
}

export const coverPagePadding: AlbumPagePadding = {
  ...initialAlbumPagePadding,
  left: 50,
  right: 23,
  top: 23,
  bottom: 23,
}

export const defaultPagePadding: AlbumPagePadding = {
  ...initialAlbumPagePadding,
  left: 23,
  right: 23,
  top: 23,
  bottom: 23,
}
