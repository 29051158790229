import { FC } from 'react'
import { Album } from '@api/gql/graphql'

type Props = {
  album: Album
}

export const AlbumItem: FC<Props> = (props) => {
  return (
    <div key={props.album.id}>
      <div className="text-sm font-bold">{props.album.title}</div>
      <div className="text-sm">
        {props.album.subtitle}
      </div>
    </div>
  )
}
