import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Role } from '@api/gql/graphql'
import { RouteSpinner } from '@components/route-spinner'
import {
  AdminLayout,
  LandingLayout,
  MainLayout,
  NotFoundLayout,
  OauthLayout,
  PaymentLayout,
  PrintLayout,
} from '@layouts/index'
import {
  AdminOrdersPage,
  ContactsPage,
  CreatePage,
  DeprecatedCreatePage,
  DeprecatedOrderPaymentLink,
  FaqPage,
  LoginFacebookPage,
  MainPage,
  NotFoundPage,
  OrderPage,
  OrdersPage,
  PaymentFailedPage,
  PaymentSuccessPage,
  PrintBookPage,
  PrintSpinePage,
  ProfilePage,
  TermsAndConditionsPage,
} from '@pages/index'
import { ProtectedRoute } from '@share/protected-route'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<LandingLayout />}>
        <Route
          path="/"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <MainPage />
            </Suspense>
          )}
        />
      </Route>
      <Route element={<PaymentLayout />}>
        <Route
          path="/payment-failed"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <PaymentFailedPage />
            </Suspense>
          )}
        />
        <Route
          path="/payment-success"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <PaymentSuccessPage />
            </Suspense>
          )}
        />
      </Route>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/create"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <CreatePage />
            </Suspense>
          )}
        />

        <Route
          path="/preview/highlights/:from_unix/:to_unix"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <DeprecatedCreatePage />
            </Suspense>
          )}
        />

        <Route
          path="/orders"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <OrdersPage />
            </Suspense>
          )}
        />
        <Route
          path="/orders/:orderId"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <OrderPage />
            </Suspense>
          )}
        />
        <Route
          path="/profile"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <ProfilePage />
            </Suspense>
          )}
        />
        <Route
          path="/faq"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <FaqPage />
            </Suspense>
          )}
        />
        <Route
          path="/contacts"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <ContactsPage />
            </Suspense>
          )}
        />
        <Route
          path="/terms-and-conditions"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <TermsAndConditionsPage />
            </Suspense>
          )}
        />
      </Route>
      <Route
        path="/admin"
        element={(
          <ProtectedRoute roles={[Role.Admin, Role.Moderator]}>
            <AdminLayout />
          </ProtectedRoute>
        )}
      >
        <Route
          path="/admin"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <AdminOrdersPage />
            </Suspense>
          )}
        />
      </Route>
      <Route path="/print" element={<PrintLayout />}>
        <Route
          path="/print/book/:albumId"
          element={<PrintBookPage />}
        />
        <Route
          path="/print/spine/:albumId"
          element={<PrintSpinePage />}
        />
      </Route>
      <Route path="/oauth" element={<OauthLayout />}>
        <Route
          path="/oauth/facebook/callback"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <LoginFacebookPage />
            </Suspense>
          )}
        />
      </Route>

      {/* Поддержка старого способа открытия оплаты заказа */}
      <Route path="/api/orders/order-checkout/:orderId" element={<DeprecatedOrderPaymentLink />} />

      <Route element={<NotFoundLayout />}>
        <Route
          path="*"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <NotFoundPage />
            </Suspense>
          )}
        />
      </Route>
    </Routes>
  )
}
