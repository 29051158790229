import { FC } from 'react'
import { ModerationStatus, OrdersApiInput, OrderStatus } from '@api/gql/graphql'
import { InputDate } from '@components/input-date'
import { moderationStatusesOptions, orderStatusesOptions } from '@constants/order'
import { faMagnifyingGlass, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDebouncedValue } from '@helpers/use-debounced-value'
import { Button } from '@nextui-org/button'
import { Input } from '@nextui-org/input'
import { Select, SelectItem } from '@nextui-org/select'
import { DateTime } from 'luxon'

type Props = {
  input: OrdersApiInput
  setInput: (value: OrdersApiInput) => void
  onRefresh: () => void
  isLoading: boolean
}

export const Filters: FC<Props> = (props) => {
  const [search, setSearch] = useDebouncedValue(props.input.search ?? '', (value) => {
    if (value === props.input.search) {
      return
    }

    props.setInput({ ...props.input, search: value })
  })

  const [peechoId, setPeechoId] = useDebouncedValue(props.input.peecho_id ?? '', (value) => {
    if (value === props.input.peecho_id) {
      return
    }

    props.setInput({ ...props.input, peecho_id: value || null })
  })

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12 gap-2 mb-2 px-4 lg:px-0">
      <div className="lg:col-span-3">
        <div className="text-tiny text-gray-500 mb-0.5">Search:</div>
        <Input
          value={search}
          startContent={<FontAwesomeIcon icon={faMagnifyingGlass} width={18} height={18} />}
          aria-label="input"
          variant="bordered"
          size="sm"
          placeholder="Customer email, name"
          isClearable
          onValueChange={setSearch}
        />
      </div>

      <div className="lg:col-span-2">
        <div className="text-tiny text-gray-500 mb-0.5">Peecho ID:</div>
        <Input
          value={peechoId}
          aria-label="input"
          size="sm"
          variant="bordered"
          placeholder="Peecho id"
          isClearable
          onValueChange={setPeechoId}
        />
      </div>

      <div className="lg:col-span-1">
        <div className="text-tiny text-gray-500 mb-0.5">Order status:</div>
        <Select
          aria-label="input"
          selectedKeys={props.input.status ? [props.input.status] : []}
          items={orderStatusesOptions}
          variant="bordered"
          placeholder="- any -"
          size="sm"
          classNames={{ value: 'text-black' }}
          onChange={(e) => {
            props.setInput({
              ...props.input,
              status: (e.target.value as OrderStatus) || null,
            })
          }}
        >
          {(item) => (
            <SelectItem key={item.id}>{item.name || '- any -'}</SelectItem>
          )}
        </Select>
      </div>

      <div className="lg:col-span-2">
        <div className="text-tiny text-gray-500 mb-0.5">Moderation status:</div>
        <Select
          aria-label="input"
          selectedKeys={props.input.moderation_status ? [props.input.moderation_status] : []}
          items={moderationStatusesOptions}
          variant="bordered"
          placeholder="- any -"
          size="sm"
          classNames={{ value: 'text-black' }}
          onChange={(e) => {
            props.setInput({
              ...props.input,
              moderation_status: (e.target.value as ModerationStatus) || null,
            })
          }}
        >
          {(item) => (
            <SelectItem key={item.id}>{item.name || '- any -'}</SelectItem>
          )}
        </Select>
      </div>

      <div className="lg:col-span-3">
        <div className="text-tiny text-gray-500 mb-0.5">Created at:</div>
        <div className="flex gap-x-2 items-center">
          <InputDate
            value={props.input.created_date_from ?? ''}
            className="min-w-[110px]"
            placeholder="01.01.2020"
            aria-label="input"
            size="sm"
            variant="bordered"
            onValueChange={(value) => {
              if (value === props.input.created_date_from) {
                return
              }

              props.setInput({
                ...props.input,
                created_date_from: DateTime
                  .fromFormat(value, 'yyyy-LL-dd')
                  .set({
                    hour: 0, minute: 0, second: 0, millisecond: 0,
                  })
                  .toISO(),
              })
            }}
          />

          <div className="text-gray-500">-</div>

          <InputDate
            value={props.input.created_date_to ?? ''}
            className="min-w-[110px]"
            placeholder="01.01.2020"
            aria-label="input"
            size="sm"
            variant="bordered"
            onValueChange={(value) => {
              if (value === props.input.created_date_to) {
                return
              }

              props.setInput({
                ...props.input,
                created_date_to: DateTime
                  .fromFormat(value, 'yyyy-LL-dd')
                  .set({
                    hour: 23, minute: 59, second: 59, millisecond: 59,
                  })
                  .toISO(),
              })
            }}
          />
        </div>
      </div>

      <div>
        <div className="text-tiny text-gray-500 mb-0.5">Apply</div>
        <Button
          variant="flat"
          size="sm"
          onClick={props.onRefresh}
          isLoading={props.isLoading}
          startContent={props.isLoading ? undefined : <FontAwesomeIcon icon={faRepeat} />}
        />
      </div>
    </div>
  )
}
