import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { AlbumPage } from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { useApolloError } from '@helpers/apollo-error'
import { PageItem } from '@pages/print-page/components'

import { getAlbum } from './api'
import { AlbumPageExtraFields } from './types'

export const PrintBookPage = () => {
  const { albumId } = useParams<{ albumId: string }>()
  const [searchParams] = useSearchParams()

  const onlyCover = !!searchParams.get('only-cover')
  const range = searchParams.get('range')

  const pagination = useMemo((): {
    fromPage: number;
    toPage: number;
  } | null => {
    if (!range) {
      return null
    }
    const [fromStr, toStr] = range.split(',')

    return {
      fromPage: Number(fromStr),
      toPage: Number(toStr),
    }
  }, [range])

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { id: albumId as string },
  })

  const pageError = useApolloError([getAlbumState.error])

  const album = getAlbumState.data?.album

  const pages = useMemo((): (AlbumPage & AlbumPageExtraFields)[] => {
    // Активные страницы
    let pagesLive = (album?.pages ?? [])
      .map((page) => ({
        ...page,
        images: page.images?.filter((image) => !image.deleted_at),
      }))
      .filter((page, index) => {
        // Первая и последняя страница это обложки. Они есть всегда даже без изображений
        if (index === 0 || (album?.pages?.length || 0) - 1 === index) {
          return true
        }

        return !!page.images?.length
      })

    if (!pagesLive.length) {
      return []
    }

    pagesLive = [
      pagesLive[0],
      {
        id: -2 as unknown as string,
        images: [],
        sort: 0,
        width: 0,
        height: 0,
      },
      ...pagesLive.slice(1),
    ]

    // Добавление добавочной страницы
    // Книга всегда должна быть с четным количеством страниц.
    // Поэтому если количество страниц нечетное добавляем пустую страницу перед задней обложкой
    //
    // В редакторе альбома эта логика не добавляется потому что количество страниц
    // будет уменьшатся через две страницы. Это может сбить с толку клиента
    const isOdd = (pagesLive?.length || 0) % 2 !== 0

    if (isOdd) {
      const additionalIndex = (pagesLive?.length || 0) - 1
      pagesLive = [
        ...pagesLive.slice(0, additionalIndex),
        {
          id: -1 as unknown as string,
          images: [],
          sort: 0,
          width: 0,
          height: 0,
        },
        ...pagesLive.slice(additionalIndex),
      ]
    }

    return (
      pagesLive
        .map((page, index) => {
          const isCover = index === 0
          const isBackCover = index === (pagesLive?.length || 0) - 1
          const isAdditional = page.id === -1 as unknown as string
          const isIntro = page.id === -2 as unknown as string
          return {
            ...page,
            // Используем флаги так как страницы могу иметь пагинацию,
            // и индекс тут не поможет найти обложки
            isCover,
            isBackCover,
            isAdditional,
            isIntro,
            isContent: !isCover && !isBackCover && !isAdditional,
          }
        })
        .filter((_, index) => {
          // Делаем сраз страниц исходя из диапазона переданных страниц
          if (!pagination) {
            return true
          }

          return (
            pagination.fromPage - 1 <= index && index <= pagination.toPage - 1
          )
        })
        .filter((page) => {
          // Отображение только обложки если передана эта настройка
          if (onlyCover) {
            return page.isCover
          }

          return true
        }) ?? []
    )
  }, [album?.pages, onlyCover, pagination])

  return (
    <>
      <Alert type={AlertType.error} message={pageError} />

      {!!album && pages.map((page) => (
        <PageItem key={page.id} album={album} albumPage={page} />
      ))}
    </>
  )
}

export default PrintBookPage
