import { ModerationStatus, OrderStatus } from '@api/gql/graphql'
import upperFirst from 'lodash/upperFirst'

export const orderStatuses = [
  OrderStatus.Paid,
  OrderStatus.Cancelled,
  OrderStatus.Created,
  OrderStatus.Refunded,
]

export const orderStatusesOptions = [
  {
    id: '',
    name: '- any -',
  },
  ...orderStatuses.map((status) => ({
    id: status,
    name: upperFirst(status).replace(/_/g, ' '),
  })),
]

export const moderationStatuses = [
  ModerationStatus.Processing,
  ModerationStatus.NeedsModeration,
  ModerationStatus.PaidInPeecho,
  ModerationStatus.SentToPeecho,
]

export const moderationStatusesOptions = [
  {
    id: '',
    name: '- any -',
  },
  ...moderationStatuses.map((status) => ({
    id: status,
    name: upperFirst(status).replace(/_/g, ' '),
  })),
]
