import { FC } from 'react'
import { declension } from '@helpers/i18n'
import { clsx } from 'clsx'

import { RangeVariant, RangeVariantType } from '../types'

type Props = {
  rangeVariant: RangeVariant;
  onSelect: () => void;
  onContinue: () => void
  onReorder: () => void
  isDisabled?: boolean;
};

export const RangeVariantItem: FC<Props> = (props) => {
  const isContinue = props.rangeVariant.type === RangeVariantType.CONTINUE
  const isReorder = props.rangeVariant.type === RangeVariantType.REORDER

  const onClick = () => {
    if (props.isDisabled) {
      return
    }

    if (isContinue) {
      props.onContinue()
      return
    }

    if (isReorder) {
      props.onReorder()
      return
    }

    props.onSelect()
  }

  return (
    <button
      type="button"
      className={clsx(
        'flex justify-between text-left px-6 lg:min-w-[400px]',
        'transition-colors w-full py-3 bg-gray-100 rounded-3xl',
        props.isDisabled ? 'opacity-40 cursor-default' : 'hover:bg-gray-200 cursor-pointer',
      )}
      onClick={onClick}
    >
      <div>
        <div>{props.rangeVariant.name}</div>

        {isContinue && (
          <div className="text-primary text-sm">
            Continue editing
          </div>
        )}

        {isReorder && (
          <div className="text-green-600 text-sm">
            Reprint
          </div>
        )}
      </div>
      <div className="text-sm text-gray-500">
        {`${props.rangeVariant.count} ${declension(
          props.rangeVariant.count,
          ['photo', 'photos', 'photos'],
        )}`}
      </div>
    </button>
  )
}
