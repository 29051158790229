import { useState } from 'react'
import {
  ModerationStatus,
  OrdersApiInput,
  OrderStatus,
} from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { Pagination } from '@components/pagination'
import { useApolloError } from '@helpers/apollo-error'
import { usePagination } from '@helpers/use-pagination'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/table'

import { getOrders } from './api'
import { ColumnKey, columns } from './columns'
import {
  CellAlbums,
  CellCover,
  CellModeration,
  CellOrder,
  CellPeecho,
  Filters,
} from './components'

export const AdminOrdersPage = () => {
  const { openModal } = useModal()

  const [ordersApiInput, setOrdersApiInput] = useState<OrdersApiInput>({
    search: '',
    peecho_id: null,
    created_date_from: null,
    created_date_to: null,
    status: OrderStatus.Paid,
    moderation_status: ModerationStatus.NeedsModeration,
  })
  const { paginationInput, onPage } = usePagination({ limit: 15 })

  const getOrdersState = useQuery(getOrders, {
    variables: {
      input: ordersApiInput,
      pagination: paginationInput,
    },
    fetchPolicy: 'cache-and-network',
  })

  const pageError = useApolloError([getOrdersState.error])

  const orders = getOrdersState.data?.orders.items
    ?? []

  return (
    <div>
      <Filters
        input={ordersApiInput}
        setInput={setOrdersApiInput}
        onRefresh={() => getOrdersState.refetch({
          input: ordersApiInput,
          pagination: paginationInput,
        })}
        isLoading={getOrdersState.loading}
      />

      <Alert type={AlertType.error} message={pageError} />

      <Table
        aria-label="table"
        className="mb-5"
        layout="fixed"
        classNames={{
          wrapper: 'p-0 border-0 shadow-none',
          td: ['border-b border-r last:border-r-0 border-gray-400'],
        }}
        showSelectionCheckboxes={false}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn width={column.width} key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={orders} emptyContent="Orders not found">
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell className="align-top">
                  {columnKey === ColumnKey.COVER && (
                    <CellCover
                      order={item}
                      onEdit={() => {
                        openModal({
                          type: ModalType.ADMIN_ORDER_MODAL,
                          orderId: item.id,
                        })
                      }}
                    />
                  )}
                  {columnKey === ColumnKey.ALBUM && (
                    <CellAlbums albums={item.albums} />
                  )}
                  {columnKey === ColumnKey.PEECHO && (
                    <CellPeecho order={item} />
                  )}
                  {columnKey === ColumnKey.ORDER && <CellOrder order={item} />}
                  {columnKey === ColumnKey.MODERATION && (
                    <CellModeration order={item} />
                  )}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Pagination
        pageInfo={getOrdersState.data?.orders.page_info}
        onPage={onPage}
      />
    </div>
  )
}

export default AdminOrdersPage
